#WEO {
	background: white;
	box-shadow: 5 196 45 0 rgb(5, 196, 45);
	animation: pulse 2s infinite;
}

@keyframes pulse {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #05C42D;
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px #05C42D;
	}
	
	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 #05C42D;
	}
}
