.swift-mobile-layout{
    position: relative;
    height: 100vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    /* background-color:rgb(249, 253, 253); */
}
.header{
    width: 100vw;
    position: absolute;
    top: 0px;
}
.content{
    margin-top: 60px;
    overflow: auto;
}
.footer{
    width: 100vw;
    position: absolute;
    bottom: 0px;
}
.MuiSnackbar-root {
 top:150px;
}
.MuiSnackbarContent-root{
    background-color: #000 !important;
    color:#fff !important;
}
.MuiSnackbarContent-message{
    margin:auto;
}